import { css, keyframes, useTheme as useAppTheme } from '@emotion/react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { Interactive, Tooltip } from '@livechat/design-system-react-components'
import { promiseRetry } from '@livechat/promise-utils'

import { UserPopoverSection } from 'components/UserPopover'
import { NavHelpFilledIcon, NavHelpIcon } from 'components/icons'
import { useInstallPrompt } from 'hooks/useInstallPrompt'
import useTemplateId from 'hooks/useTemplateId'
import { useTracking } from 'hooks/useTracking'
import { useUser } from 'hooks/useUser'
import { useAllowedRoutes } from 'layouts/routes'
import { useAppDispatch, useAppSelector } from 'lib/store'
import { EVENT_NAMES } from 'lib/tracking'
import { Theme } from 'styles/theme'

const ProductSwitcher = dynamic(() => promiseRetry(() => import('components/ProductSwitcher')), {
	ssr: false,
})

const Main = styled.main`
	position: absolute;
	inset: 0;
	display: flex;
	background-color: #050505;
	padding: 6px;
	padding-left: 0;
`

const MainContent = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space6};
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	padding: ${({ theme }) => theme.spaces.space7} ${({ theme }) => theme.spaces.space6};
	border-radius: 16px;
`

const Nav = styled.nav`
	width: 56px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	padding-top: 60px;
	box-shadow: ${({ theme }) => theme.boxShadows.lg};
	position: relative;

	@media (max-width: 900px) {
		padding: ${({ theme }) => theme.spaces.space3};
		padding-top: 60px;
	}
`

const NavGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;

	[role='tooltip'] {
		// make sure tooltips are not covered by maximized widget
		z-index: 2147483640;
		svg {
			width: 20px;
			height: 20px;
		}
	}
`

const HelpButton = styled.button`
	border: none;
`

const navLinkStyles = (theme: Theme, isActive: boolean) => css`
	width: 42px;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${isActive ? theme.colors.light.grayscale[50] : theme.colors.light.grayscale[200]};
	opacity: ${isActive ? 1 : 0.5};
	text-decoration: inherit;
	padding: ${theme.spaces.space2};
	border-radius: ${theme.borderRadiuses.md};
	background-color: ${isActive ? theme.colors.light.grayscale[700] : 'transparent'};
	position: relative;
	transition-property: background-color, opacity;
	transition-duration: 200ms;
	transition-timing-function: ${theme.transitions.easings.css.smooth};

	svg {
		transform: scale(0.9);
		z-index: ${theme.zIndexes.high};
		transition: transform 200ms ${theme.transitions.easings.css.smooth};
	}

	:hover {
		opacity: 1;
		background-color: ${theme.colors.light.grayscale[700]};
		svg {
			transform: scale(1);
			fill: ${theme.colors.light.grayscale[50]};
		}
	}
`

const bubbleScale = keyframes`
	0% {
		scale: 1
	}
	100% {
		scale: 1.4
	}
`

const OuterBubble = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #eff0fd;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	animation: ${bubbleScale} linear 1s infinite alternate 1s;
`

const InnerBubble = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #d3d5fc;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	animation: ${bubbleScale} linear 1s infinite alternate;
`

const BubbleWrapper = styled.div`
	position: absolute;
	display: grid;
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	place-items: center;

	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
	z-index: ${({ theme }) => theme.zIndexes.above};
	${({ theme }) =>
		theme.isMobile
			? css`
					width: calc(100% + 2 * ${theme.spaces.space3});
					height: calc(100% + 2 * ${theme.spaces.space3});
			  `
			: css`
					width: 100%;
					height: calc(100% + 2 * ${theme.spaces.space5});
			  `}
`

const NotificationDot = styled.div`
	position: absolute;
	top: ${({ theme }) => (theme.isMobile ? theme.spaces.space2 : theme.spaces.space1)};
	right: ${({ theme }) => (theme.isMobile ? theme.spaces.space2 : theme.spaces.space1)};
	width: 10px;
	height: 10px;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	background-color: ${({ theme }) => theme.colors.light.notification};
`

const TooltipContent = styled.div`
	width: max-content;
`

type Props = {
	children: React.ReactNode
}

function AppFrame({ children }: Props) {
	const dispatch = useAppDispatch()
	const router = useRouter()
	const user = useUser()
	const track = useTracking()
	const appTheme = useAppTheme()
	const isSuccessStepActive = useAppSelector((state) => state.currentModal === 'SuccessStepModal')
	const isOpenAiGuidelineTooltipVisible = useAppSelector((state) => state.currentGuidelineTooltip === 'OpenAiArchives')
	const { isVisible: isInstallPromptVisible } = useInstallPrompt()
	const { withTemplateId } = useTemplateId()
	const allowedRoutes = useAllowedRoutes()
	const [isWidgetMaximized, setIsWidgetMaximized] = React.useState(false)

	const handleOpenAiArchivesGuidelineTooltipDismiss = () => {
		dispatch({ type: 'currentGuidelineTooltip/set', payload: null })
	}

	React.useEffect(() => {
		if (window.OpenWidget) {
			window.OpenWidget.once('ready', () => {
				setIsWidgetMaximized(window.OpenWidget.get('state')?.visibility === 'maximized')
			})

			window.OpenWidget.on('visibility_changed', ({ visibility }) => {
				setIsWidgetMaximized(visibility === 'maximized')
			})
		}
	}, [])

	return (
		<Main>
			{user.role === 'administrator' && (
				<Nav>
					<NavGroup>
						<ProductSwitcher />
						{allowedRoutes.map((routeConfig) => {
							const { title, route, icon } = routeConfig
							const isActive = router.route.startsWith(route)
							if (route === '/chat-archives/openai' && isOpenAiGuidelineTooltipVisible) {
								return (
									<Tooltip
										transitionDuration={500}
										withFadeAnimation
										isVisible={isOpenAiGuidelineTooltipVisible}
										key={route}
										placement="right-start"
										triggerRenderer={
											<Link href={withTemplateId(route)} css={navLinkStyles(appTheme, isActive)} aria-label={title}>
												{isActive ? icon.active : icon.default}
											</Link>
										}
									>
										<TooltipContent>
											<Interactive
												header="Chats with Assistant will appear in Archives section"
												text="When your customers interact with the assistant, their conversations will be saved and displayed for quick preview."
												primaryButton={{
													label: 'Dismiss',
													handleClick: handleOpenAiArchivesGuidelineTooltipDismiss,
												}}
											/>
										</TooltipContent>
									</Tooltip>
								)
							}
							return (
								<Tooltip
									key={route}
									triggerOnHover
									placement="right"
									kind="invert"
									transitionDelay={500}
									triggerRenderer={
										<Link
											href={'omitTemplateIdInNavigation' in routeConfig ? route : withTemplateId(route)}
											css={navLinkStyles(appTheme, isActive)}
											aria-label={title}
										>
											{isActive ? icon.active : icon.default}
											{isSuccessStepActive && isActive && (
												<BubbleWrapper>
													<OuterBubble />
													<InnerBubble />
												</BubbleWrapper>
											)}
											{'notificationDot' in routeConfig && routeConfig.notificationDot && isInstallPromptVisible && (
												<NotificationDot data-testid="notification-dot" />
											)}
										</Link>
									}
								>
									<TooltipContent>{title}</TooltipContent>
								</Tooltip>
							)
						})}
					</NavGroup>
					<NavGroup>
						{window.OpenWidget && (
							<Tooltip
								triggerOnHover
								placement="right"
								kind="invert"
								transitionDelay={500}
								triggerRenderer={
									<HelpButton
										aria-label="Help"
										css={navLinkStyles(appTheme, false)}
										onClick={() => {
											if (window.OpenWidget.get('state')?.visibility === 'maximized') {
												setIsWidgetMaximized(false)
												window.OpenWidget.call('minimize')
											} else {
												setIsWidgetMaximized(true)
												window.OpenWidget.call('maximize')
											}
											track(EVENT_NAMES.USER_PROFILE_HELP_CENTER_CLICKED)
										}}
									>
										{isWidgetMaximized ? <NavHelpFilledIcon /> : <NavHelpIcon />}
									</HelpButton>
								}
							>
								<TooltipContent>Help Center</TooltipContent>
							</Tooltip>
						)}
						<UserPopoverSection onClick={() => window.OpenWidget?.call('minimize')} />
					</NavGroup>
				</Nav>
			)}
			<MainContent>{children}</MainContent>
		</Main>
	)
}

export default AppFrame
