import createSvgIcon from './createSvgIcon'

const Copy = createSvgIcon(
	<path d="m16.375 6.75h-12.5c-1.03553391 0-1.875.83946609-1.875 1.875v10.5c0 1.0355339.83946609 1.875 1.875 1.875h12.5c1.0355339 0 1.875-.8394661 1.875-1.875v-10.5c0-1.03553391-.8394661-1.875-1.875-1.875zm-.375 12h-12v-9.75h12zm6-13.875v11.625c0 .6213203-.5036797 1.125-1.125 1.125s-1.125-.5036797-1.125-1.125v-11.25h-14.25c-.62132033-.00000001-1.12499998-.50367967-1.12499998-1.125s.50367965-1.125 1.12499998-1.125h14.625c.4972809 0 .9741945.19754408 1.3258252.54917479.3516307.3516307.5491748.82854429.5491748 1.32582521z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default Copy
