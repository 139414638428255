import { SerializedStyles, css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import FieldError from './FieldError'
import Fieldset from './Fieldset'
import Label from './Label'
import { formControlReset } from 'styles/reset'

type Props = JSX.IntrinsicElements['input'] & {
	label: React.ReactNode
	error?: string
	length?: 'sm' | 'md' | 'lg' | 'xl'
	compact?: boolean
	leftAdornment?: React.ReactNode
	rightAdornment?: React.ReactNode
	innerInset?: React.ReactNode
	button?: React.ReactNode
	fullFieldsetWidth?: boolean
	labelCss?: SerializedStyles
}

export const StyledInput = styled.input<Pick<Props, 'length' | 'compact'>>`
	${formControlReset};
	width: 100%;
	min-width: 60px;
	padding: ${({ theme }) =>
		theme.isMobile ? `6px ${theme.spaces.space3}` : `${theme.spaces.space5} ${theme.spaces.space6}`};
	color: ${({ theme }) => theme.colors.light.secondaryTextColor};
	line-height: 1.4;
	border-radius: ${({ theme }) => theme.borderRadiuses.md};
	background-color: ${({ theme }) => theme.colors.light.surfaceVariant};
	${({ theme }) => theme.typography.paragraph}

	max-width: ${({ theme, length = 'xl' }) =>
		({
			sm: '70px',
			md: '135px',
			lg: '280px',
			xl: theme.isMobile ? '100%' : '380px',
		}[length])};

	${({ theme, compact }) =>
		compact &&
		css`
			padding: 6px 8px;
			${!theme.isMobile && theme.typography.caption}
		`}

	&:focus {
		outline-style: solid;
		outline-width: 1px;
		outline-color: ${({ theme }) => theme.colors.light.surfaceInteractive};
	}

	&:disabled {
		cursor: not-allowed;
	}
`

const InputWithInsetWrapper = styled.div`
	position: relative;
`

const LabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space6};
	min-height: 28px;
`

const InnerInsetWrapper = styled.div<Pick<Props, 'length'>>`
	position: absolute;
	inset: 0;
	width: 100%;
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.light.surfaceVariant};
	border-radius: ${({ theme }) => theme.borderRadiuses.md};
	max-width: ${({ theme, length = 'xl' }) =>
		({
			sm: '70px',
			md: '135px',
			lg: '280px',
			xl: theme.isMobile ? '100%' : '380px',
		}[length])};
`

const InputWithButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: ${({ theme }) => theme.spaces.space3};
`

const Input = React.forwardRef<HTMLInputElement, Props>(
	(
		{
			label,
			error,
			length,
			compact,
			leftAdornment,
			rightAdornment,
			innerInset,
			fullFieldsetWidth,
			button,
			labelCss,
			...restProps
		},
		ref,
	) => {
		const id = React.useId()
		let InputWrapper = React.Fragment as React.ComponentType<React.PropsWithChildren>

		if (button) {
			InputWrapper = InputWithButtonWrapper
		} else if (innerInset) {
			InputWrapper = InputWithInsetWrapper
		}

		return (
			<Fieldset fullWidth={fullFieldsetWidth}>
				<LabelWrapper>
					{leftAdornment}
					<Label htmlFor={id} required={restProps.required} css={labelCss}>
						{label}
					</Label>
					{rightAdornment}
				</LabelWrapper>
				<InputWrapper>
					<StyledInput
						{...restProps}
						{...(innerInset && { disabled: true })}
						length={length}
						compact={compact}
						id={id}
						ref={ref}
					/>
					{button}
					{innerInset && <InnerInsetWrapper>{innerInset}</InnerInsetWrapper>}
				</InputWrapper>
				{error && <FieldError>{error}</FieldError>}
			</Fieldset>
		)
	},
)

Input.displayName = 'Input'

export default Input
