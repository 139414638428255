import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Button from 'components/Button'
import UseAsyncFn from 'components/UseAsyncFn'
import { InfoIcon } from 'components/icons'
import { useTemplate } from 'hooks/useTemplate'
import { useTemplates } from 'hooks/useTemplates'
import { useToast } from 'hooks/useToast'
import { updateTemplate } from 'lib/api'

const RedInfoIcon = styled(InfoIcon)`
	color: ${({ theme }) => theme.colors.light.error};
`

const DisabledWidgetInfo = styled.div<{ screenPosition: 'left' | 'right' }>`
	width: fit-content;
	height: 65px;
	background-color: #fad9dc;
	position: absolute;
	border: 1px solid #dba9ad;
	border-radius: ${({ theme }) => theme.borderRadiuses.xxl};
	z-index: ${({ theme }) => theme.zIndexes.above};
	padding: ${({ theme }) => theme.spaces.space4};
	display: flex;
	align-items: center;
	gap: 10px;
	box-shadow: ${({ theme }) => theme.boxShadows.md};
	${({ theme }) => theme.typography.captionMedium};

	${({ theme, screenPosition }) =>
		screenPosition &&
		css`
			${screenPosition}: 5px;
			bottom: ${theme.isMobile ? '80px' : '-3em'};
		`}

	${({ theme }) =>
		theme.isMobile &&
		css`
			left: 10px;
			right: 10px;
			height: 50px;
		`}
`

type Props = React.HTMLAttributes<HTMLDivElement> & {
	templateId: string
	screenPosition?: 'left' | 'right'
}

function DisabledWidgetBanner({ templateId, screenPosition = 'right', ...restProps }: Props) {
	const { mutateTemplate } = useTemplate()
	const { mutateTemplates } = useTemplates()
	const showToast = useToast()

	async function enableWidgetVisibility() {
		try {
			const updatedTemplate = await updateTemplate(templateId, { isWidgetEnabled: true })
			mutateTemplate(updatedTemplate)
			mutateTemplates()
			showToast('success')
		} catch {
			showToast('error')
		}
	}

	return (
		<DisabledWidgetInfo screenPosition={screenPosition} {...restProps}>
			<RedInfoIcon />
			Customers cannot see this widget
			<UseAsyncFn key="make-visible" asyncFn={enableWidgetVisibility}>
				{({ loading, callAsyncFn }) => (
					<Button kind="primary" loading={loading} onClick={callAsyncFn} disableHover compact>
						Make visible
					</Button>
				)}
			</UseAsyncFn>
		</DisabledWidgetInfo>
	)
}

export default DisabledWidgetBanner
