import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React from 'react'

import { promiseRetry } from '@livechat/promise-utils'

import DisabledWidgetBanner from './DisabledWidgetBanner'
import FakeBrowser from './FakeBrowser'
import { WidgetVisibility } from './Widget'
import WidgetOuter from './WidgetOuter'
import Button from 'components/Button'
import { InfoIcon } from 'components/icons'
import { useIsOnboarding } from 'hooks/useIsOnboarding'
import { useUser } from 'hooks/useUser'
import { WIDGET_PREVIEW_ID } from 'lib/constants'
import { useAppSelector } from 'lib/store'
import { Alignment } from 'types/theme'

const Widget = dynamic(() => promiseRetry(() => import('./Widget')), { ssr: false })

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colors.light.primaryTextColor};
`

const DisabledMinimizedInfo = styled.div<{ screenPosition: 'left' | 'right' }>`
	width: 260px;
	height: 90px;
	background-color: ${({ theme }) => theme.colors.light.surface};
	position: absolute;
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
	z-index: ${({ theme }) => theme.zIndexes.popover};
	padding: ${({ theme }) => theme.spaces.space4};
	display: flex;
	align-items: center;
	gap: 10px;
	box-shadow: ${({ theme }) => theme.boxShadows.md};
	${({ theme }) => theme.typography.smallcaps};

	${({ screenPosition }) =>
		screenPosition &&
		css`
			${screenPosition}: 1em;
			bottom: 1em;
		`}
`

const StyledButton = styled(Button)`
	${({ theme }) => theme.typography.smallcaps};
	padding: 0;
	position: relative;
	top: 4px;
	font-weight: bold;
	text-decoration: underline;
`

const Preview = styled.div<{ isOnboarding: boolean; isSuccessStepActive: boolean }>`
	height: ${({ isSuccessStepActive }) => (isSuccessStepActive ? '652px' : '100%')};
	width: ${({ isSuccessStepActive }) => (isSuccessStepActive ? '352px' : '100%')};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: ${({ theme }) => theme.zIndexes.modal};

	${({ theme, isOnboarding }) =>
		!theme.isMobile &&
		isOnboarding &&
		css`
			@media (max-height: 940px) {
				justify-content: flex-end;
			}
		`}
	${({ isSuccessStepActive }) =>
		isSuccessStepActive &&
		css`
			@media (max-width: 900px) {
				width: 400px;
			}
		`}
`

type Props = React.ComponentProps<typeof Widget> & {
	previewUrl?: string | null
	isWidgetEnabled?: boolean
}

function WidgetPreview({ previewUrl, isWidgetEnabled = true, ...widgetProps }: Props) {
	const theme = useTheme()
	const user = useUser()
	const isOnboarding = useIsOnboarding()
	const newTheme = useAppSelector((state) => state.theme)
	const [alignment, setAlignment] = React.useState<Alignment | null>(null)
	const isSuccessStepActive = useAppSelector((state) => state.currentModal === 'SuccessStepModal')
	const selectedVisibilityTarget = useAppSelector((state) => state.selectedVisibilityTarget)
	const [widgetVisibility, setWidgetVisibility] = React.useState<WidgetVisibility>('maximized')
	const [shouldMaximize, setShouldMaximize] = React.useState(false)
	const isWidgetHidden =
		['minimized', 'hidden'].includes(widgetVisibility) &&
		(selectedVisibilityTarget === 'desktop' ? newTheme?.disableMinimized : newTheme?.mobileDisableMinimized)

	React.useEffect(() => {
		const themeToApply = newTheme || widgetProps.theme
		if (
			themeToApply &&
			typeof themeToApply.offsetY === 'number' &&
			typeof themeToApply.offsetX === 'number' &&
			typeof themeToApply.mobileOffsetY === 'number' &&
			typeof themeToApply.mobileOffsetX === 'number'
		) {
			setAlignment(themeToApply)
		}
	}, [newTheme, widgetProps.theme, setAlignment, selectedVisibilityTarget])

	const disabledWidgetBanner = !isWidgetEnabled ? (
		<DisabledWidgetBanner templateId={user.organizationId} screenPosition={alignment?.screenPosition} />
	) : null

	if (theme.isMobile) {
		return (
			<Preview id={WIDGET_PREVIEW_ID} isOnboarding={isOnboarding} isSuccessStepActive={isSuccessStepActive}>
				<WidgetOuter isOnboarding={isOnboarding} isSuccessStepActive={isSuccessStepActive}>
					<Widget {...widgetProps} />
				</WidgetOuter>
				{disabledWidgetBanner}
			</Preview>
		)
	}

	return (
		<Preview id={WIDGET_PREVIEW_ID} isOnboarding={isOnboarding} isSuccessStepActive={isSuccessStepActive}>
			{isSuccessStepActive && (
				<WidgetOuter
					alignment={alignment}
					isOnboarding={isOnboarding}
					isSuccessStepActive={isSuccessStepActive}
					selectedVisibilityTarget={selectedVisibilityTarget}
				>
					<Widget {...widgetProps} />
				</WidgetOuter>
			)}
			{!isSuccessStepActive && (
				<FakeBrowser
					alignment={alignment}
					defaultPreviewURL={previewUrl ?? ''}
					{...(disabledWidgetBanner && { banner: disabledWidgetBanner })}
				>
					<WidgetOuter
						alignment={alignment}
						isOnboarding={isOnboarding}
						selectedVisibilityTarget={selectedVisibilityTarget}
					>
						<Widget
							{...widgetProps}
							setWidgetVisibility={setWidgetVisibility}
							setShouldMaximize={setShouldMaximize}
							shouldMaximize={shouldMaximize}
						/>
					</WidgetOuter>
					{isWidgetHidden && (
						<DisabledMinimizedInfo
							screenPosition={
								(selectedVisibilityTarget === 'desktop' ? newTheme?.screenPosition : newTheme?.mobileScreenPosition) ??
								'right'
							}
						>
							<InfoIcon />
							<div>
								{'You have chosen '}
								<StyledLink href="/configurator/customize-look">Custom Widget Launcher →</StyledLink>
								{' that disables minimized widget.'}
								<StyledButton kind="text" disableHover onClick={() => setShouldMaximize(true)}>
									Click here to preview maximized
								</StyledButton>
							</div>
						</DisabledMinimizedInfo>
					)}
				</FakeBrowser>
			)}
		</Preview>
	)
}

export default WidgetPreview
