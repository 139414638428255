import * as React from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { m } from 'framer-motion'

import { config } from 'lib/config'

type Props = {
	children: React.ReactNode
	reversed?: boolean
	axis?: 'x' | 'y'
	fullHeight?: boolean
}

const Wrapper = styled(m.div)<{ isAnimationOverridden: boolean; fullHeight: boolean }>`
	width: 100%;
	${({ fullHeight }) => fullHeight && 'height: 100%;'}
	overflow-y: auto;
	padding-right: ${({ theme }) => theme.spaces.space6};

	${({ isAnimationOverridden }) =>
		isAnimationOverridden &&
		`
		opacity: 1 !important;
		transform: none !important;
	`}
`

function SlideInWrapper({ children, reversed, axis = 'x', fullHeight = false }: Props) {
	const theme = useTheme()
	const [shouldAnimate, setShouldAnimate] = React.useState(true)
	const isTest = config.isTest

	React.useEffect(() => {
		if (isTest) {
			setShouldAnimate(false)
		}
	}, [isTest])

	return (
		<Wrapper
			initial={{ opacity: 0, [axis]: reversed ? 30 : -30 }}
			animate={{ opacity: 1, [axis]: 0 }}
			transition={{ duration: 0.3, ease: theme.transitions.easings.motion.speedySwift }}
			isAnimationOverridden={!shouldAnimate}
			fullHeight={fullHeight}
		>
			{children}
		</Wrapper>
	)
}

export default SlideInWrapper
