import { includes } from '@livechat/data-utils'

import { TEMP_ENTITY_PREFIX } from './constants'

export const clearTempIds = <E extends { id: string }>(entities: E[]): Array<Omit<E, 'id'> | E> =>
	entities.map(({ id, ...entity }) => (id.startsWith(TEMP_ENTITY_PREFIX) ? entity : { id, ...entity }))

const isChromeOnIPadOS13OrHigher = () => navigator.platform === 'MacIntel' && includes('iPad', navigator.userAgent)

export const isMobile = (): boolean =>
	typeof navigator !== 'undefined' && /mobile/gi.test(navigator.userAgent) && !isChromeOnIPadOS13OrHigher()

export const isValidHexColor = (value: string) => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(value)

export const scrollToPreviewElement = (testId: string, block: ScrollLogicalPosition = 'center') => {
	// workaround for rerendering issues causing the scrolling to be aborted: https://github.com/facebook/react/issues/20770
	setTimeout(() => {
		const targetElement = document.querySelector(`[data-testid="${testId}"]`)

		if (targetElement) {
			targetElement.scrollIntoView({ block, behavior: 'smooth' })
		}
	}, 100)
}

export const scrollToPreviewTop = () => {
	// workaround for rerendering issues causing the scrolling to be aborted: https://github.com/facebook/react/issues/20770
	setTimeout(() => {
		const contactCard = document.querySelector(`[data-testid="contact-card"]`)

		contactCard?.parentElement?.parentElement?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' })
	}, 100)
}

type UADataNavigator = {
	userAgentData: {
		brands: string
		mobile: boolean
		platform: string
	}
}

const hasUserAgentDataSupport = (nav: Navigator): nav is UADataNavigator & Navigator => {
	return 'userAgentData' in nav
}

export const getOperatingSystem = () => {
	const userAgent = window.navigator.userAgent,
		platform = hasUserAgentDataSupport(window.navigator)
			? window.navigator.userAgentData.platform
			: window.navigator.platform,
		macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'],
		windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
		iosPlatforms = ['iPhone', 'iPad', 'iPod']

	if (macosPlatforms.indexOf(platform) !== -1) {
		return 'macOS'
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		return 'iOS'
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		return 'Windows'
	} else if (/Android/.test(userAgent)) {
		return 'Android'
	} else if (/Linux/.test(platform)) {
		return 'Linux'
	}

	return 'unknown'
}

export const getProtocol = (url: string): string | null => {
	try {
		return new URL(url).protocol.split(':')[0]
	} catch {
		return null
	}
}

export const ensureProtocol = (url: string) => {
	return getProtocol(url) !== null ? url : `https://${url}`
}

export const getOrdinalSuffix = (number: number) => {
	const suffixes = ['th', 'st', 'nd', 'rd']
	const lastTwoDigits = number % 100
	return number + (suffixes[(lastTwoDigits - 20) % 10] || suffixes[lastTwoDigits] || suffixes[0])
}
